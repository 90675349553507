import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './Padres.css'

export default function Padres() {
    return (     
        <div id="PadresCafe" className='PadresCafe'>
        <br />
        <br />
        <p>Y colorín colorado, esta historia va empezando</p>
        <br />
            <div class="row">
                <div class="column">
                    <h5>Elba Josefina Díaz Castillón</h5>
                    <h5>Federico Ayala Rodríguez</h5>
                    <p>(Padres de la novia)</p>
                </div>
                <div class="column">
                    {/* <h5>Georgina Sánchez Ramírez</h5> */}
                    <h5>María Isabel Farías Sosa</h5>
                    <p>(Madre del novio)</p>
                </div>
            </div>
            <p>Padrinos</p>
            <div class="row">
                <div class="column">
                    <h5>Martha Elena Díaz Castillón</h5>
                    <h5>Miguel Ángel Murillo García</h5>
                    <p>(Padrinos de la novia)</p>
                </div>
                <div class="column">
                    <h5>Alma Angélica Farías Sosa</h5>
                    <h5>Jaime González Aguilar</h5>
                    <p>(Padrinos del novio)</p>
                </div>
            </div>
            {/* Todo comienza con un sí... */}
            <br />
            <br />
            <br />
        </div>
    )
  }



