import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import QRCode from 'react-qr-code';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
  } from "react-router-dom";
import db from '../firebase/config'
import { collection, getDocs, addDoc,doc, updateDoc, deleteDoc, getDoc   } from "firebase/firestore";
import './formatoInvitados.css'
import Error from '../assetsGeneral/close.png'
import Success from '../assetsGeneral/checked.png'
import moment from "moment";
import Swal from 'sweetalert2'

function InvitadosIngreso(){
 
//  const fechaBoda = moment("18.11.2022 20.30.00", "DD.MM.YYYY HH.mm.ss");

    const [array,setArray] = useState([]);
    const { id } = useParams();  
    const [Nombre, setNombre] = useState('');
    const [Mesa, setMesa] = useState('');
    const [CantPersons, setCantPersons] = useState('');

    const [Acceso, setAcceso] = useState(0);

    useEffect(() => {
        const obtenerDatos = async() => {
            const deleteRef = doc(db, 'InvitadosAlex', id); 

            getDoc(deleteRef).then((snapshot) => { 
            console.log(snapshot.data(), snapshot.id) 
            console.log(snapshot.data().Nombre);
            console.log(snapshot.data().Mesa);
            console.log(snapshot.data().CantPersonas);
            setNombre(snapshot.data().Nombre)
            setMesa(snapshot.data().Mesa)
            setCantPersons(snapshot.data().CantPersonas)
            setAcceso(snapshot.data().Acceso)
            });
        }
        obtenerDatos();
      }, []);

 
      const handleClick = () => {
     
        const updateCliente = async() => {
          
            const docRef = doc(db, "Invitados", id);
            try {
              await updateDoc(docRef, {
                Acceso: 1,
              });  
              console.log("Document update with ID: ", docRef.id);
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Invitado recibido',
                showConfirmButton: false,
                timer: 1500
              })
            } catch (e) {
              console.error("Error adding document: ", e);
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                timer: 1500
              })
            }     
          }
          updateCliente();
      };
     
      const FechaBoda = new Date('Octuber 28, 2023 14:00:00');
      const FechaActual = Date.now();
      if(FechaActual < FechaBoda)
      {
        return(
          <div className='DivIInvitados'>
                 <div className='divTextosInvitados'>
                   <br/>
                   <img src={Error} style={{width: 100}}/>
                   <br/>
                   <br/> 
                   <br/>
                   <p id='textInvitadoTrueTitulo'>Codigo válido a partir de: </p>
                   <p id='textInvitadoTrueTitulo'>{FechaBoda.toLocaleString()}</p>
                   <br/>
                 </div>
             </div>
        )
      }
      else{
        {
         
          console.log('Fecha valida');

        if(Acceso === 0)
        {
            return (        
                <div className='DivIInvitados'>
                  <div className='divTextosInvitados'>
                  <br/>
                    <img src={Success} style={{width: 100}}/>
                    <p id='textInvitadoTrueTitulo'>¡Bienvenidos! </p>
                    <br/>
                    <p id='textInvitadoTrue'>{Nombre}</p>
                    <p id='textInvitadoTrue'>Mesa: {Mesa}</p>
                    <p id='textInvitadoTrue'>Personas: {CantPersons}</p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <button className='buttonConfirm' onClick={handleClick}>Guardar</button>
                  </div>
                </div>
            )
        }
        else if(Acceso === 1)
        {
        return (   
          <div className='DivIInvitados'>
          <div className='divTextosInvitados'>
            <br/>
            <img src={Error} style={{width: 100}}/>
            <br/>
            <br/>
            <br/>
            <p id='textInvitadoTrueTitulo'>Lo siento, codigo ya fue utilizado! </p>
            {moment().format('MMMM Do YYYY, h:mm:ss a')}
            <br/>
          </div>
        </div>
        )
      }

        }
      }
  }

  export default InvitadosIngreso;


