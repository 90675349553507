import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CodigoVestimenta.css'
import VestimentaFormal from '../../assets/img/vestimentaFormal.png'

export default function CodigoVestimenta() {
    return (     
        <div id='stCodigoVest' className='CodigoVestBack'>
           <br/>
           <br />
           <h1 id='Titulo2'>Código de vestimenta</h1>
           
           <h4>Semi-Formal</h4>
           <img src={VestimentaFormal} id='imgVestimenta' />           
           <br />
           <br />
           <h5>(Favor de no llevar blanco o beige).</h5>
           <hr />
           <br />
        </div>
    )
  }



