import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Rings from '../../assets/img/rings.png'
import Copas from '../../assets/img/Copas.png'
import Iglesia from '../../assets/img/iglesia2.png'
import './Lugar.css'

export default function Lugar() {

    let url = 'https://goo.gl/maps/WDFtv4opGGTAa3JW8'

    let url2 = "https://goo.gl/maps/DkMN13s9sNEDfmWW8"
    return (     
        <div id="stLugar">
            <br />
            <br />
            <h1 id='TituloLugarCafe'>¿Cuándo y Dónde?</h1>
            <br />
            <h1 id='subTituloLugarCafe'> 18.Noviembre.2023</h1>
            <br />
            <h1 id='subTituloLugarCafs'>Ceremonia religiosa</h1>
            <h1 id='subTituloLugarCafs'>Parroquia del Espíritu Santo</h1>
            <img id='ImgRings' src={Iglesia} />
            
            <br />
            <br />
            
            Felipe Sevilla del Río S/N, Jardines Vista Hermosa III, 28017 Colima, Col.<br />
            
            <div>
                <button className='ButtonT1Cafe' onClick={() => { window.location.href = url2; } }>
                Ver mapa
                </button>
            </div>
            <br />
            <h1 id='subTituloLugarCafe'>Recepción</h1>
            <h1 id='subTituloLugarCafe'>La Cañada, jardín de eventos</h1>
            <img id='ImgRings' src={Copas} />
            <div className='TextLugar'>
            Continuación, Hacienda la Cañada km 3, Col.<br />
            </div>
            <div>
                <button className='ButtonT1Cafe' onClick={() => { window.location.href = url; } }>
                Ver mapa
                </button>
            </div>
            <br />
            <br />
        </div>
    )
  }



