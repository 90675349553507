// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage, ref, uploadBytes, getDownloadURL, listAll  } from 'firebase/storage'
 import { useState } from "react";

 import { getFirestore, updateDoc } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDYf3ng_SADhdK7vqxgQ8VTvCbrpexQwOg",
  authDomain: "invita2-4a706.firebaseapp.com",
  projectId: "invita2-4a706",
  storageBucket: "invita2-4a706.appspot.com",
  messagingSenderId: "363570364579",
  appId: "1:363570364579:web:98957fdf5a41ae82283f16"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)

export async function List(){
    // const [array,setArray] = useState(null);
  
    const fruits = [];
    const storageRef = ref(storage, '/AlexyGoretti/')
    
    await listAll(storageRef).then((res) => {
      console.log('Resp 0');
      res.items.forEach(async (itemRef) => {
        console.log('Resp');
        console.log(itemRef);
        // return itemRef
        const dato = await getDownloadURL(itemRef)
        fruits.push(dato)
        // setArray(array => [...array,dato] );
      });
    }).catch((error) => {    
      console.log(error);
    });
  
    return fruits;
  }


const db = getFirestore(app);
export default db;