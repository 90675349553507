import React, { useState, useEffect, Component  } from 'react';
import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css'

class Navbbar extends Component{
  constructor(props) {
    super(props)
    this.state = { matches: window.matchMedia("(min-width: 768px)").matches };
  }

  componentDidMount() {
    const handler = e => this.setState({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addEventListener('change', handler);
  }

  click(){
    console.log("Click")
  }
  render() {
    return (
      <div >
      {this.state.matches && (
           <div>
           <Nav id="navbar" className="justify-content-center" activeKey="/home">
             <Nav.Item>
               <Nav.Link className="Link" >Inicio</Nav.Link>
             </Nav.Item>
             <Nav.Item>
               <Nav.Link className="Link" href="#stPadres" eventKey="link-1">Padres</Nav.Link>
             </Nav.Item>
             <Nav.Item>
               <Nav.Link className="Link" href="#stLugar" eventKey="link-2">Lugar</Nav.Link>
             </Nav.Item>
             <Nav.Item>
               <Nav.Link className="Link" href="#stItinerario" eventKey="link-3">Itinerario</Nav.Link>
             </Nav.Item>
             <h1>NUESTRA BODA</h1>
             <Nav.Item>
               <Nav.Link className="Link" href='#stMesaRegalos' eventKey="link-4">Regalos</Nav.Link>
             </Nav.Item>
             <Nav.Item>
               <Nav.Link className="Link" href='#stCodigoVest' eventKey="link-5">Vestimenta</Nav.Link>
             </Nav.Item>
             {/* <Nav.Item>
               <Nav.Link className="Link" eventKey="link-5">Asistencia</Nav.Link>
             </Nav.Item> */}
             <Nav.Item>
               <Nav.Link className="Link"  href='#stGaleria' eventKey="link-5">Galería</Nav.Link>
             </Nav.Item>
             <Nav.Item>
               <Nav.Link className="Link"  href='#stSugerencia' eventKey="link-5">Sugerencias</Nav.Link>
             </Nav.Item>
           </Nav>
           </div>
        
      )}
      {!this.state.matches && (
        <div>
          <nav id="navbar">
            <div class="navbar">
              <div class="container nav-container">
                  <input class="checkbox" type="checkbox" name="" id="" />
                  <div class="hamburger-lines">
                    <span class="line line1"></span>
                    <span class="line line2"></span>
                    <span class="line line3"></span>
                  </div>  
                  <div class="logo">
                    <h1 id='TituloSmall'>NUESTRA BODA</h1>
                  </div>
                <div class="menu-items">
                  {/* <li><a href="/stHome">Home</a></li> */}
                  <li><a href="#stPadres">Padres</a></li>
                  <li><a href="#stLugar">Lugar</a></li>
                  <li><a href="#stItinerario">Itinerario</a></li>
                  <li><a href="#stMesaRegalos">Regalos</a></li>
                  <li><a href="#stCodigoVest">Vestimenta</a></li>
                  <li><a href="#stGaleria" onClick={this.click}>Galería</a></li>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )}
      </div>
    );
  }
}
export default Navbbar;