import React, { useState, useEffect, Button, Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbbar from './Components/Navbar/Navbar';
import Padres from './Components/Padres/Padres';
import Lugar from './Components/Lugar/Lugar';
import Itinerario from './Components/Itinerario/Itinerario'
import MesaRegalos from './Components/MesaRegalos/MesaRegalos';
import CodigoVestimenta from './Components/CodigoVestimenta/CodigoVestimenta';
import Recuerdos from './Components/Recuerdos/Recuerdos'
import Footer from './Components/Footer/Footer';
import ImagenPortada from './Components/Portada/Portada'
import SugerenciaHos from './Components/sugerenciaHos/SugerenciaHos'
import Carta from '../Compartidos/Carta'

import CartaBack1 from '../AlexyGoretti/assets/img/card/CartaP1.png'
import CartaBack2 from '../AlexyGoretti/assets/img/card/CartaP2.png'
import './main.css'
import InicioBoda from '../assetsGeneral/sello.png'

import love from './assets/songs/LaVieenRose.mp3'

import Confirma from './Components/Confirmanos/Confirma';


class MainIrvingElisa extends Component
{
    
    constructor(props) {
        super(props);
        this.state = {
            width: '100%',
            // cleft: 0,
            // ctop: 0,
            isLogged: false
            // mostrarBoton: true
        };
    }
    

    render(){    
        const styles = {
            containerStyle: {
                width: this.state.width,
            }
            };
        const { containerStyle } = styles;
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        
        const open = async() => {
            let audio = new Audio(love)
            audio.play()

            this.state.isLogged = false;
            this.setState({
                width: '0%',
                // isLogged: true
            })

            await sleep(1000)

            this.setState({
                isLogged: true
            })
           
            
    }
    
    

    if (!this.state.isLogged) {
        return(
        <div>
            <div className="rowCard">
                <div className="columnCard">
                    <img style={containerStyle} id='CartaIzquierda' src={CartaBack1} />        
                </div>
                {!this.isLogged &&
                <div id="columnCenter">
                    <button id='buttonInicio' onClick={open}><img src={InicioBoda} /></button>
                </div>
                }

                <div className="columnCard">
                    <img style={containerStyle} id='CartaDerecha' src={CartaBack2} />  
                </div>
            </div>
            
        </div>
        )
    }
    else{
    console.log('Click en botón, cargar pantalla principal');
    console.log('Activar musica');
    
        return (       
            <div >
            <Navbbar />
            <ImagenPortada />
            <Padres />
            <Lugar />
            <Itinerario />
            <MesaRegalos />
            <CodigoVestimenta />
            <Recuerdos />
            <SugerenciaHos />
            <Confirma />
            <Footer />
            </div>
        )
    }
  }
}


export default MainIrvingElisa;