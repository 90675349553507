import React, { useState, useEffect, useMemo, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import QRCode from 'react-qr-code';
import { collection, getDocs, addDoc, getFirestore, deleteDoc, doc } from "firebase/firestore";
import db from '../firebase/config'
import Swal from 'sweetalert2'
//import {FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon} from 'react-share'
import { MaterialReactTable } from 'material-react-table';
import {
  Box,
  // Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  IconButton,
  // MenuItem,
  // Stack,
  // TextField,
  // Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

export default function InvitadosMain() {

  const [message, setMessage] = useState('');
  const [array,setArray] = useState([]);
  const [CantidadPersonas, setCantidadPersonas] = useState('');
  const [PersonaMesa, setPersonaMesa] = useState('');
  const [QR,setQR] = useState('Hola Mundo!');
  const [Render,setRender] = useState(true);

  useEffect(() => {
    const obtenerDatos = async() => {
      if(Render){
        console.log('RENDER');
        setArray([])
        const datos = await getDocs(collection(db,'Invitados'))
        console.log('TRAE DATOS: ');
    
        datos.forEach((documento) => {
            var objects = {
              id: documento.id,
              CantPersonas: documento.data().CantPersonas,
              Nombre: documento.data().Nombre,
              Mesa: documento.data().Mesa,
              Acceso: documento.data().Acceso
            };

            setArray(oldArray => [...oldArray,objects] );
        });
        setRender(false)
      }
    }
    obtenerDatos();
  }, []);

  useEffect(
    () => {
      console.log("dependency1 and dependency2", QR);
    },
    [QR]
  );
  

  const onImageCownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width + 200;
      canvas.height = img.height + 200;

      ctx.font = "20px Fira Sans";
      ctx.fillStyle = "#c89c7d";
      ctx.textAlign = "center";
      ctx.fillText("¡Celebra con nosotros!", 230, 35);
      ctx.font = "52px serif";
      ctx.fillText("Elisa & Irving", 230, 80);
      ctx.drawImage(img, 100, 90);
      ctx.fillText("18.11.2023", 230, 390);
      ctx.font = "20px serif";
      ctx.fillText(message, 230, 410);
      ctx.fillText("Personas "+CantidadPersonas, 230, 430);

      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    this.setState({ brand: `data:image/svg+xml;base64,${btoa(svgData)}` })
  };
  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const handleChange2 = (event) => {
    setCantidadPersonas(event.target.value);
  };
  const handleChange3 = (event) => {
    setPersonaMesa(event.target.value);
  };
  const handleClick = () => {
    SendDatos();

    setMessage('')
    setPersonaMesa('')
    setCantidadPersonas('')

  };

  // useEffect(() => {clickTabla()}, [QR])

  const clickTabla = (row) =>{
    //var liga = "https://localhost:3000/Invitados/" + row.original.id
    var liga = "https://invita2.mx/Invitados/" + row.original.id
    setQR(liga);
    
    setMessage(row.original.Nombre) //Set Nombre
    setCantidadPersonas(row.original.CantPersonas) //Set cantidad personas
   
    // console.log(row.original.id);
    //var liga = "https://invita2.mx/Invitados/"+InvitadosList[0].Id+"/"+InvitadosList[0].Nombre+"/"+ InvitadosList[0].Personas+"/"+ InvitadosList[0].Mesa;
  
    // onImageCownload(row.original.Nombre,   row.original.CantPersonas);
  }

  //   {
  //     name: {
  //       firstName: 'John',
  //       lastName: 'Doe',
  //     },
  //     address: '261 Erdman Ford',
  //     city: 'East Daphne',
  //     state: 'Kentucky',
  //   },
  //   {
  //     name: {
  //       firstName: 'Jane',
  //       lastName: 'Doe',
  //     },
  //     address: '769 Dominic Grove',
  //     city: 'Columbus',
  //     state: 'Ohio',
  //   },
  //   {
  //     name: {
  //       firstName: 'Joe',
  //       lastName: 'Doe',
  //     },
  //     address: '566 Brakus Inlet',
  //     city: 'South Linda',
  //     state: 'West Virginia',
  //   },
  //   {
  //     name: {
  //       firstName: 'Kevin',
  //       lastName: 'Vandy',
  //     },
  //     address: '722 Emie Stream',
  //     city: 'Lincoln',
  //     state: 'Nebraska',
  //   },
  //   {
  //     name: {
  //       firstName: 'Joshua',
  //       lastName: 'Rolluffs',
  //     },
  //     address: '32188 Larkin Turnpike',
  //     city: 'Charleston',
  //     state: 'South Carolina',
  //   },
  // ];
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Nombre', //access nested data with dot notation
        header: 'Nombre',
        size: 150,
      },
      {
        accessorKey: 'CantPersonas',
        header: 'Cantidad Personas',
        size: 150,
      },
      {
        accessorKey: 'Mesa',
        header: 'Mesa',
        size: 150,
      },
      {
        accessorKey: 'Acceso',
        header: 'Acceso',
        size: 150,
      },
      
    ],
    [],
  );

  const SendDatos = async() => {
    try {
      const docRef = await addDoc(collection(db, "Invitados"), {
        Nombre: message,
        CantPersonas: CantidadPersonas,
        Mesa: PersonaMesa,
        Acceso: 0
      });
      //console.log("Document written with ID: ", docRef.id);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (e) {
      //console.error("Error adding document: ", e);
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Oops...',
        showConfirmButton: false,
        timer: 1500
      })
    }      
  //SendDatos();
}

function handleDeleteRow(row){
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    
    if (result.isConfirmed) {
      console.log(row.original.id)
      const DeletesCliente = async() => {
        try {
          await deleteDoc(doc(db, "Invitados", row.original.id));
          console.log("Document drop with ID: ", row.original.id);
          
          array.splice(row.index, 1);
          setArray([...array]);
        } catch (e) {
          console.error("Error adding document: ", e);
        }     
      }
      DeletesCliente();
      
      Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
    }
  })
  // obtenerDatos();
}


  return (
    <div>
      <p>Listas de invitados!</p>
      
      
      <label>Nombre: </label>
      <input
        type="NombreFamilia"
        id="Nombre"
        name="message"
        onChange={handleChange}
        value={message}
      />
      <br />
      <label>Cantidad personas: </label>
      <input
        type="CantidadPersonas"
        id="CantPersonas"
        name="CantPersonas"
        onChange={handleChange2}
        value={CantidadPersonas}
      />
      <br /> 
      <label>Mesa: </label>
      <input
        type="Mesa"
        id="Mesa"
        name="Mesa"
        onChange={handleChange3}
        value={PersonaMesa}
      />
      <br />
      <button onClick={handleClick}>Guardar Invitados</button>
      <br />
      <br />
      

      <QRCode id="QRCode" bgColor="#c89c7d" value={QR} />
      <br />
      <input type="button" value="Download QR" onClick={onImageCownload} />
      <MaterialReactTable 
      columns={columns} 
      data={array} 
      enableRowActions
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
        <button onClick={() => clickTabla(row)}>Generar QR</button>
        <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
        </Box>
        )}
        />
      <br />
      <br />
      <br />
      {/* <h2>Message: {message}</h2> */}
      {/* <h2>Updated: {updated}</h2> */}

    </div>
  )
}