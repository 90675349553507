import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'

export default function Footer() {

    return (     
        <div className='DerechosBack'>
            <footer>
                <h3>Todos los derechos reservados &copy;</h3>
                <a href="https://invita2.mx/" target="_blank">Inivita2.mx</a>
            </footer>
        </div>
    )
  }





