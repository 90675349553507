import React, { useState, useEffect,useCallback  } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageViewer from 'react-simple-image-viewer';
import './Recuerdos.css'

import { UploadFile, List, storage } from '../../../firebase/config';
import {getStorage, ref, uploadBytes, getDownloadURL, listAll  } from 'firebase/storage'

export default function Recuerdos() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setimages] = useState([]);
    // const images = [
    //     img1,
    //     img4,
    //     img7,
    //     img2,
    //     img3,
    //     img5,
    //     img6,
    //     img7,
    //     img8,
    //     img9,
    //     img10,
    //     img11,
    //     img12,
    //     img13,
    //     img14,
    // ];

    useEffect(() => {
    
        let fileNamesArray;
        const storageRef = ref(storage,"ElisaeIrving")

        // const storageRef = ref(storage, '1.jpg')
        console.log(storageRef);
        listAll(storageRef)
            .then((res) => {
                fileNamesArray = res.items.map((itemRef) => itemRef.name);
                const promises = res.items.map((itemRef) => getDownloadURL(itemRef));
                return Promise.all(promises)
            })
            .then((urlsArray) => {
              // const objectsArray = urlsArray.map((elem, index) => ({ name: fileNamesArray[index], logoURL: elem }));
                const objectsArray = urlsArray.map((elem, index) => (elem));
                setimages(objectsArray);
                console.log(objectsArray);
            });
            // setRender(true)
    }, []);


    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (     
        <div id='stGaleria' className='backFotos'>
            <br />
            <h1 id='TituloRecuerdos'>Nuestros recuerdos</h1>
            <div>
            {images.map((src, index) => (
                <img
                src={ src }
                onClick={ () => openImageViewer(index) }
                width="200"
                key={ index }
                style={{ margin: '2px' }}
                alt=""
                />
            ))}

            {isViewerOpen && (
                <ImageViewer
                src={ images }
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            )}
            </div>
            <br />
            Comparte con nosotros las fotos de nuestra boda, utilizando el #NosCasamosIrving&Elisa
        </div>
    )
  }



