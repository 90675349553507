import React, { useState, useEffect,useCallback  } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SugerenciaHos.css'
import hotel from "../../assets/img/hotel.png"

let url1 = "https://goo.gl/maps/2sJiQoFt1gKVWBuN9"
let url2 = "https://goo.gl/maps/4JRzxeV8NPbWoYEF9"
let url3 = "https://goo.gl/maps/Z3j3KWiior4zwkjr7"
let url4 = "https://goo.gl/maps/xWeqiGNFFPG4ikWq7"

export default function SugerenciaHos() {
  
    return (     
        <div className='SugerenciaHos' id="stSugerencia">
            <br />
            <h1 id='Titulo2'>Sugerencias de hospedaje</h1>
              <div class="row">
                <div class="column">
                    <img id='ImgHotel' src={hotel} />
                    <br />
                    <p id='pSugerencias'>
                    Hotel Posada La Loma 
                    L. E. Bracamontes #197
                    CP. 49340, Tapalpa, Jalisco, México
                    Tel: +52 343 432 0168
                    </p>
                    <div>
                        <button id='ButtonHospedaje' class="btn btn-info" onClick={() => { window.location.href = url1; } }>
                        Ver mapa
                        </button>
                    </div>
                </div>
                <div class="column">
                <img id='ImgHotel' src={hotel} />
                    <br />
                    <p id='pSugerencias'>
                    Hotel La Casa de Maty
                    Matamoros 69, Centro, 49340 Tapalpa, Jal.
                    Teléfono: +523434320189
                    </p>
                    <div>
                        <button id='ButtonHospedaje' class="btn btn-info" onClick={() => { window.location.href = url2; } }>
                        Ver mapa
                        </button>
                    </div>
                </div>
                <div class="column">
                <img id='ImgHotel' src={hotel} />
                    <br />
                    <p id='pSugerencias'>
                    Hotel Tapalpa de mis Amores
                    Agustín Yáñez 40, Centro, 49340 Tapalpa, Jal.
                    Teléfono: +523434320132
                    </p>
                    <div>
                        <button id='ButtonHospedaje' class="btn btn-info" onClick={() => { window.location.href = url3; } }>
                        Ver mapa
                        </button>
                    </div>
                </div>
                <div class="column">
                <img id='ImgHotel' src={hotel} />
                    <br />
                    <p id='pSugerencias'>
                    Rancho Monterralo Hotel Y Cabañas 
                    Camino La Frontera-Lagunillas Km 5.9, San Martin, 49340 Tapalpa, Jal.
                    Teléfono:  33 3100 4422
                    </p>
                    <div>
                        <button id='ButtonHospedaje' class="btn btn-info" onClick={() => { window.location.href = url4; } }>
                        Ver mapa
                        </button>
                    </div>
                </div>
                <hr />
            </div>
            <br />
        </div>
    )
  }



