import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Itinerario.css'
import Iglesia from '../../assets/img/iglesia2.png'
import Celebration from '../../assets/img/celebration.png'
import pareja from '../../assets/img/pareja.png'

export default function Itinerario() {
    return (     
        <div id='ItinerarioBackCafe' className='ItinerarioBackCafe'>
            <br />
            <br />
            <h1 id='TituloItinerario'>Itinerario</h1>
            {/* <ul class="timeline">
                <li data-year="2017" data-text="Lorem ipsum dolor sit amet, consectetur.">
                </li>
                <li data-year="2018" data-text="Lorem ipsum dolor sit amet, consectetur."></li>
            </ul> */}
            <div class="row">
                <div class="column">
                    <p>Boda a la Iglesia</p>
                    <img className='ImgItinerario' src={Iglesia} />
                    <br />
                    <br />
                    <p>19:00 Hrs</p>
                </div>
                <div class="column">
                    <p>Recepción</p>
                    <img className='ImgItinerario' src={pareja} />
                    <br />
                    <br />
                    <p>20:30 Hrs</p>
                </div>
                <hr />
            </div>
            
            <br />
            <br />
            {/* <hr class="style18" /> */}
        </div>
    )
  }



