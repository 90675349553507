import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Portada.css'
import PortadaImg from '../../assets/img/test.png'
import { useReactCountdown } from 'use-react-countdown';

export default function ImagenPortada() {
  let dateToEndCountdownAt2 = "Nov 18, 2023 00:00:00";
  const { days, hours, minutes, seconds } = useReactCountdown(dateToEndCountdownAt2);

    return (     
      <div id='stHome' className='Portada'>
          <img id='Principal' src={PortadaImg}/>
          <div className="NombresNovios">
            <h2 id='NombreNovios'>Ana Elisa</h2>
            <h2 id='NombreNovios'>Ayala Díaz</h2>
              <div class="separator">
                <h2 id='NombreNovios'>&</h2>
              </div>
            <h2 id='NombreNovios' >Irving Paul</h2>
            <h2 id='NombreNovios'>Farías Sosa</h2>
            <div class="rowTimer">
                <div class="columnTimer">
                  <p id='NumeroCould'>{days}</p>
                  <h6>Días</h6>
                </div>
                <div class="columnTimer">
                <p id='NumeroCould'>:</p>
                </div>
                <div class="columnTimer">
                  <p id='NumeroCould'>{hours}</p>
                  <h6>Horas</h6>
                </div>
                <div class="columnTimer">
                <p id='NumeroCould'>:</p>
                </div>
                <div class="columnTimer">
                  <p id='NumeroCould'>{minutes}</p>
                  <h6>Minutos</h6>
                </div>
                <div class="columnTimer">
                <p id='NumeroCould'>:</p>
                </div>
                <div class="columnTimer">
                  <p id='NumeroCould'>{seconds}</p>
                  <h6>Segundos</h6>
                </div>
            </div>
          </div>



        


      </div>
    )
  }



 {/* {days} : {hours} : {minutes} : {seconds}

            </div> */}
            