import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Portada.css'
import PortadaImg from '../../assets/img/Portada3.jpg'
import { useReactCountdown } from 'use-react-countdown';

export default function ImagenPortada() {
  let dateToEndCountdownAt = "Oct 28, 2023 00:00:00";
  const { days, hours, minutes, seconds } = useReactCountdown(dateToEndCountdownAt);

    return (     
      <div id='stHome' className='Portada'>
          <img id='Principal' src={PortadaImg}/>
          <div className="NombresNovios">
            <h2 id='NombreNovios'>Susana Goretti</h2>
            <h5>Rodriguez Salgado</h5>
              <div class="separator">
                <h2 id='NombreNovios'>&</h2>
              </div>
            <h2 id='NombreNovios' >Jorge Alejandro</h2>
            <h5>Rosas Sánchez</h5>
            <div class="rowTimer">
                <div class="columnTimer">
                  <p id='NumeroCould'>{days}</p>
                  <h6>Días</h6>
                </div>
                <div class="columnTimer">
                <p id='NumeroCould'>:</p>
                </div>
                <div class="columnTimer">
                  <p id='NumeroCould'>{hours}</p>
                  <h6>Horas</h6>
                </div>
                <div class="columnTimer">
                <p id='NumeroCould'>:</p>
                </div>
                <div class="columnTimer">
                  <p id='NumeroCould'>{minutes}</p>
                  <h6>Minutos</h6>
                </div>
                <div class="columnTimer">
                <p id='NumeroCould'>:</p>
                </div>
                <div class="columnTimer">
                  <p id='NumeroCould'>{seconds}</p>
                  <h6>Segundos</h6>
                </div>
            </div>
          </div>



        


      </div>
    )
  }



 {/* {days} : {hours} : {minutes} : {seconds}

            </div> */}
            