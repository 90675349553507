import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import gato from "../assetsGeneral/gato.gif"
import "./main.css"

export default function Principal() {
    return (     
        <div className='Maincss'>
            <img src={gato} />
            <p id='texty'>Trabajando en algo grande! Esperanos!</p>
        </div>
    )
  }






