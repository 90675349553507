import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Confirma.css'
import Swal from 'sweetalert2'

class Confirma extends React.Component {
    constructor(props) 
        {
        super(props);
        this.state = {value: '', mensaje: '', asiste: 1};
        
    
        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
      }
    
      handleChange(event) {
        this.setState({value: event.target.value});
      }
      handleChange2(event) {
        this.setState({mensaje: event.target.value});
      }
      onChangeValue(event) {
        
        this.setState({asiste: event.target.value});
      }
      handleSubmit(event) {
        if(this.state.value === '')
        {
          // alert('Por favor agrega tu nombre');
          Swal.fire({
            title: 'Advertencia!',
            text: 'Por favor agrega tu nombre',
            icon: 'warning',
            confirmButtonText: 'Confirmar'
          })
        }else{
          
          let mensaje = '';
          console.log(this.state.asiste);
          if(this.state.asiste == 1)
          {
            console.log('ASISTE');
            mensaje = "Tu invitado: " + this.state.value + " Asistirá a tu evento y te deja un bonito mensaje: " + this.state.mensaje;
          }
          else{
            
            mensaje = "Tu invitado: " + this.state.value + " NO podra asistir a tu evento, pero te deja un bonito mensaje: " + this.state.mensaje;
          }
          
          let nueva = mensaje.replace(/ /g, "+")
  
          console.log(nueva);
          try {
            const response = fetch(' https://api.telegram.org/bot6183035043:AAGJ9zOcrxkA38RnEc83jYOBasMi_jilV44/sendMessage?chat_id=-987956477&text='+nueva, {
              method: 'GET',
              headers: {
                Accept: 'application/json',
              },
            });
      
            if (!response.ok) {
              throw new Error(`Error! status: ${response.status}`);
            }
  
            const result = response.json();
  
            console.log('result is: ', JSON.stringify(result, null, 4));
      
            // setData(result);
          } catch (err) {
            // setErr(err.message);
          } finally {
            // setIsLoading(false);
            Swal.fire({
              title: 'Enviado!',
              text: 'Enviamos tu mensaje',
              icon: 'success',
              confirmButtonText: 'Confirmar'
            })
          }
        }
    
        


        event.preventDefault();

        
      }
      
  render() {
    return (     
        <div className='principalConfirma' >
          <br/>
          <h1 id='TituloLugar'>Confirma tu asistencia</h1>
          <br/>
          <p>¡Para confirmar tu asistencia y obtener tu pase de entrada, te invitamos a ponerte en contacto con alguno de los novios! </p>
          <p>¡Estaremos encantados de recibir tu confirmación y asegurarnos de que tengas acceso sin problemas al evento!</p>
          
          <br/>
           
        {/*
        <form onSubmit={this.handleSubmit}>
        <label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-default">Nombre</span>
            </div>
            <input type="text" value={this.state.value} onChange={this.handleChange} class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
          </div>
        </label>
        <div onChange={this.onChangeValue} className='boxRadio' class="form-check">
        
        <label for="exampleFormControlTextarea1">¿asistirás?</label>
        <br/>
          <input className='radioitem' type="radio" value={1}  name="flexRadioDefault" checked/> Sí
          <input className='radioitem' type="radio" value={0}  name="flexRadioDefault"/> No
        </div>

       
        <div class="form-group">
          <label for="exampleFormControlTextarea1"> Escribenos un bonito mensaje:</label>
          <textarea  value={this.state.mensaje} onChange={this.handleChange2} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <br/>
        <input class="ButtonT1" type="submit" value="Enviar" />
      </form>
      <br/> */}
      </div>
    )
  }
}

export default Confirma;




