import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rings from '../../assets/img/rings.png'
import Iglesia from '../../assets/img/iglesia.png'
import './Lugar.css'

export default function Lugar() {

    let url = 'https://goo.gl/maps/5kwMNdXXm8TttMYT6'

    let url2 = "https://goo.gl/maps/Taro3qeExNk9M3pf6"
    return (     
        <div id="stLugar">
            <br />
            <br />
            <h1 id='TituloLugar'>¿Cuándo y Dónde?</h1>
            <br />
            <h1 id='LugarFecha'> 28.Octubre.2023</h1>

            <h1 id='subTituloLugar'>Ceremonia religiosa</h1>
            <h1 id='subTituloLugar'>PARROQUIA DE SAN ANTONIO DE PADUA</h1>
            <img id='ImgRings' src={Iglesia} />
            
            <br />
            <br />
            
            Álvaro Obregón, Tapalpa, Jalisco<br />
            
            <div>
                <button className='ButtonT1' onClick={() => { window.location.href = url2; } }>
                Ver mapa
                </button>
            </div>
            <br />
            <h1 id='subTituloLugar'>Recepción</h1>
            <h4 id='subTituloLugar'>RANCHOS LOS CEDROS</h4>
            <img id='ImgRings' src={Rings} />
            <div className='TextLugar'>
            kilometro 7 carretera tapalpa a san gabriel<br />
            Tapalpa, Jalisco<br />
            </div>
            <div>
                <button className='ButtonT1' onClick={() => { window.location.href = url; } }>
                Ver mapa
                </button>
            </div>
            <br />
            <br />
        </div>
    )
  }



