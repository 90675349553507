import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Sobre from '../../assets/img/Sobre.png'
import './MesaRegalos.css'
// import Dropdown from 'react-bootstrap/Dropdown';
import Amazonlogo from '../../assets/img/AmazonLogo.png'
import Liverpool from '../../assets/img/liverpooLogo.jpg'

export default function MesaRegalos() {
    // clickMesaRegalos(){
    //     console.log('Click')
    // }

    let url = 'https://mesaderegalos.liverpool.com.mx/milistaderegalos/51126133?_ga=2.117219078.1258546879.1675904622-1404385153.1675904622'
    let url2 = 'https://www.amazon.com.mx/wedding/susana-goretti-rodriguez-salgado-jorge-alejandro-rosas-s%C3%A1nchez-tapalpa-october-2023/registry/2I4ADFRFXSQOP'

    return (     
        <div id='stMesaRegalos'>
            <br />
            <br />
            <h1 id='TituloLugar'>Mesa de regalos</h1>
            <p>Para nosotros lo más importante es tu presencia, pero si desean obsequiarnos algo estas son nuestras sugerencias: </p>
            <p>¡Gracias!</p>
            <div class="row">
                <div class="column">
                    <button id='ButtonImg' onClick={() => { window.location.href = url2; } }>
                        <img id='imgButton' className='ImgItinerario' src={Amazonlogo} border="0"/> 
                    </button>

                </div>
                <div class="column">
                    <button id='ButtonImg' onClick={() => { window.location.href = url; } }>
                        <br />
                        <img id='imgButton2' className='ImgItinerario' src={Liverpool} /> 
                    </button>
                    
                </div>
            </div>

            {/* BUZON */}
            {/* <p/>puede ser cualquier cosa de su libre elección o efectivo.</p> */}
            {/* <p>Te dejamos nuestros datos o el día de la boda podrás encontrar un buzón y depositar tu regalo.</p>
            <p>¡Gracias!</p> */}
            {/* <img src={Sobre} id='ImgRegalos'/>
            <h6>Efectivo el dia de la boda</h6>
            <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                Datos Bancarios
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <p>BANCOMER</p>
                {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> 
            </Dropdown.Menu>
            </Dropdown> */}
        </div>
    )
  }



