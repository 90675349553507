import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CodigoVestimenta.css'
import VestimentaFormal from '../../assets/img/Formal.png'

export default function CodigoVestimenta() {
    return (     
        <div id='stCodigoVest' className='CodigoVestBackCafe'>
           <br/>
           <br />
           <h1 id='TituloCodigoVestimenta'>Código de vestimenta</h1>
           
           <h4>Formal</h4>
           <img src={VestimentaFormal} id='imgVestimenta' />           
           <br />
           <br />
           <p>(Favor de no llevar blanco o beige).</p>
           <hr />
           <br />
        </div>
    )
  }



