import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './Padres.css'

export default function Padres() {
    return (     
        <div id="stPadres" className='Padres'>
        <br />
        <br />
        <p>Los Simpsons no lo predijeron, pero es cierto ¡Vamos a casarnos!</p>
        <br />
            <div class="row">
                <div class="column">
                    <h5>Susana Salgado Chávez </h5>
                    <h5>Héctor Rodriguez Sandoval </h5>
                    <p>(Padres de la novia)</p>
                </div>
                <div class="column">
                    <h5>Georgina Sánchez Ramírez</h5>
                    <h5>Alejandro Rosas Gómez</h5>
                    <p>(Padres del novio)</p>
                </div>
            </div>
            Todo comienza con un sí...
            <br />
            <br />
            <br />
        </div>
    )
  }



