import React from 'react';
import './App.css';
// import RoutesPage from './routes/Routes';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

import "./AlexyGoretti/assets/fonts/BirdsOfParadise.ttf"
import Main from './Principal/main';
// import PortadaImg from './assets/img/Portada.jpg'

import MainAlexyGiretti from './AlexyGoretti/main';
import MainIrvingElisa from './Irving&Elisa/main'
import InvitadosMain from './Invitados/InvitadosMain';
import InvitadosIngreso from './Invitados/InvitadosIngreso';
import InvitadosMainAlex from './Invitados/InvitadosMainAlex';
import InvitadosIngresoAlex from './Invitados/InvitadosIngresoAlex';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Main />} />
            <Route exact path="/GoreAlex2023" element={<MainAlexyGiretti />} />
            <Route exact path="/BodaElisaIrving" element={<MainIrvingElisa />} />
            <Route path="/Invitados/:id" element={<InvitadosIngreso />} />
            <Route exact path="/InvitadosEI" element={<InvitadosMain />} />
            <Route path="/InvitadosAlex/:id" element={<InvitadosIngresoAlex />} />
            <Route exact path="/InvitadosGA" element={<InvitadosMainAlex />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
